/* eslint-disable */
// @ts-ignore
import { createApp } from 'vue'
// @ts-ignore
import App from './App.vue'
import router from './router'
import store from './store'
import {registerComponents} from "../globalComponents";

// createApp(App).use(store).use(router).mount('#app')
const app = createApp(App)
  .use(store)
  .use(router);

registerComponents(app);

app.mount("#app");
