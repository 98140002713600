/* eslint-disable */
// @ts-ignore
import { defineAsyncComponent } from 'vue'
export const registerComponents = ( app:any ) => {
  app.component("NavBar", defineAsyncComponent(() => import("@/components/NavBar.vue")))
  app.component("MainSection", defineAsyncComponent(() => import("@/components/MainSection.vue")))
  app.component("Info", defineAsyncComponent(() => import("@/components/Info.vue")))
  app.component("BottomSection", defineAsyncComponent(() => import("@/components/BottomSection.vue")))
  app.component("Footer", defineAsyncComponent(() => import("@/components/Footer.vue")))
  // Mobile
  app.component("NavBarMobile", defineAsyncComponent(() => import("@/components/mobile/NavBarMobile.vue")))
  app.component("MainSectionMobile", defineAsyncComponent(() => import("@/components/mobile/MainSectionMobile.vue")))
  app.component("InfoMobile", defineAsyncComponent(() => import("@/components/mobile/InfoMobile.vue")))
  app.component("BottomSectionMobile", defineAsyncComponent(() => import("@/components/mobile/BottomSectionMobile.vue")))
  app.component("FooterMobile", defineAsyncComponent(() => import("@/components/mobile/FooterMobile.vue")))
}
